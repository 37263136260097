.TableConcept {
  margin: 0 auto !important;
  width: 95%;
}

/* CUERPO TABLA */

.TableConcept tr td{
  white-space: normal !important;

}

.TableConcept td:nth-child(1),
.TableConcept thead th:nth-child(1),
.TableConcept td:nth-child(2),
.TableConcept thead th:nth-child(2){
  width: 120px !important;
  text-align: center;

}

.TableConcept td:nth-child(3),
.TableConcept td:nth-child(4){
  text-align: justify;
  height: auto;
}


.TableConcept td:nth-child(5){
  text-align: right;
}

.TableConcept td:nth-child(7),
.TableConcept td:nth-child(8),
.TableConcept td:nth-child(9){
  text-align: center;
}

/* RESPONSE TABLA */

@media only screen and (max-width: 1390px) {
  .TableConcept  {
      font-size: 13px;
      width: 97% !important;

  }
}

@media only screen and (max-width: 1320px) {
  .TableConcept  {
      font-size: 12px;
      width: 97% !important;

  }
}

@media only screen and (max-width: 1059px) {
    .TableConcept  {
        font-size: 9.5px;
        width: 98% !important;

    }
}

@media only screen and (max-width: 890px) {
    .TableConcept  {
        font-size: 8.5px;
    }
    .TableConcept{
        width: 100% !important;
    }
}


.buttoncancel{
  background-color: red !important;
}


.TableConcept{
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;
}

.TexAreaAutosize{
  width: 89%;
  margin: 0 auto;
  height: 89px !important;
}

.TexAreaAutosize_data{
  width: 100%;
  margin: 0 auto;
  min-height:  89px !important;;
}

